/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import useStyles from './FilterTree.styles'

/* Material-Icons */
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxCheckedIcon from '@mui/icons-material/CheckBox'

/* Redux */
import FilterTreeRedux from './FilterTree.Redux'
import DrawerRedux from '../Drawer.Redux'

/* Types */
import { tFilterBrands } from 'src/shared/frontendTypes/filterTree'

interface Props {
  brands: tFilterBrands
  inAccordion?: boolean
}

const Brands: React.FC<Props> = ({ brands, inAccordion }) => {
  const classes = useStyles()
  const { activeBrands, toggleBrand } = FilterTreeRedux()
  const { toggle } = DrawerRedux()

  return <>
    <List
      className={classes.filter}
      component='nav'
      subheader={ !inAccordion 
        ? <ListSubheader>Marken</ListSubheader>
        : null
      }
    >
      {brands.map(brand=>{

        const checked = (Array.isArray(activeBrands) && activeBrands.includes(brand.id))

        return <ListItem 
          key={brand.id}
          component='li' button
          onClick={(e:React.KeyboardEvent | React.MouseEvent)=>{
            toggleBrand(!checked,brand.id)
            toggle(e)
          }}
        >

          <ListItemIcon>
            {!checked
              ? <CheckBoxIcon />
              : <CheckBoxCheckedIcon />}
          </ListItemIcon>

          <ListItemText>
            {brand.title} <sup>({brand.products})</sup>
          </ListItemText>
          
        </ListItem>

      })}
    </List>
    <Divider />
  </>

}

export default Brands


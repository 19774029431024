/* Redux */
import { useSelector, useDispatch } from 'src/redux/hooks'

const Redux = () => {
  const open = useSelector(store => store.App.showNavigationDrawer)
  
  const dispatch = useDispatch()

  const toggle = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) return

    dispatch({ type: 'navigationDrawerToggle', payload: false })
  }

  return { open, toggle }
}

export default Redux
/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import useStyles from './FilterTree.styles'

/* Material-Icons */
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxCheckedIcon from '@mui/icons-material/CheckBox'
import RadioIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioCheckedIcon from '@mui/icons-material/RadioButtonChecked'

/* Redux */
import FilterTreeRedux from './FilterTree.Redux'
import DrawerRedux from '../Drawer.Redux'

/* Types */
import { filter } from 'src/shared/frontendTypes/filterTree'

interface Props {
  filter: filter
  inAccordion?: boolean
}

const Filter: React.FC<Props> = ({ filter, inAccordion }) => {
  const classes = useStyles()
  const { activeFilters, changeFilter } = FilterTreeRedux()
  const { toggle } = DrawerRedux()

  return <List
    className={classes.filter}
    component='nav'
    subheader={ !inAccordion 
      ? <ListSubheader>{filter.title}</ListSubheader>
      : null
    }
  >
    {filter.options.map(option=>{

      const { type, display } = filter
      const activeFilter = activeFilters[filter.id]
      let checked = false

      if (Array.isArray(activeFilter) && activeFilter.includes(option.id))
        checked = true
      else if (activeFilter === option.id) checked = true

      return <ListItem 
        key={option.id}
        component='li' button
        onClick={(e:React.KeyboardEvent | React.MouseEvent)=>{
          changeFilter(type,!checked,filter.id,option.id)
          toggle(e)
        }}
      >

        <CheckIcon {...{ display, checked }} />

        <ListItemText>
          {option.title} <sup>({option.products})</sup>
        </ListItemText>
        
      </ListItem>

    })}
  </List>

}

export default Filter


const CheckIcon: React.FC<{
  display: string,
  checked: boolean,
}> = ({ display, checked }) => {
  const classes = useStyles()
  
  return <ListItemIcon className={classes.filterListIcon}>
    {(display === 'checkbox' || display === 'icons') && (
      <>{!checked ? <CheckBoxIcon /> : <CheckBoxCheckedIcon />}</>
    )}

    {display === 'radio' && (
      <>{!checked ? <RadioIcon /> : <RadioCheckedIcon />}</>
    )}
  </ListItemIcon>
  
}

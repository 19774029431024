import { combineReducers } from '@reduxjs/toolkit'

import AppReducer from './reducers/app'
import UserReducer from './reducers/user'
import FilterReducer from './reducers/filter'
import WarenkorbReducer from './reducers/warenkorb'
import WunschlisteReducer from './reducers/wunschliste'
import KasseReducer from './reducers/kasse'

const rootReducer = combineReducers({
  App: AppReducer,
  User: UserReducer,
  Filter: FilterReducer,
  Warenkorb: WarenkorbReducer,
  Wunschliste: WunschlisteReducer,
  Kasse: KasseReducer,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>

import config from 'src/config'

/**
 * Sendet einen GET-Request an den Server.
 * 
 * @returns Antwort als Javascript-Objekt
 */
export interface getOptions {
  url: string
  signal?: AbortSignal
  local?: boolean
}
export const get = async ({ 
  url, signal, local 
}:getOptions):Promise<unknown> => {

  const res = await fetch(
    `${config.api_server[local ? 'local' : 'online']}/${url}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      signal,
    }
  )
  .then(res => res.json())
  .catch(e => { handleError(e); return null })

  return res
}

/**
 * Sendet einen POST-Request an den Server.
 * 
 * @returns Antwort als Javascript-Objekt
 */
export interface postOptions extends getOptions {
  body: unknown
}
export const post = async ({ 
  url, body, signal, local 
}:postOptions):Promise<unknown> => {

  const res = await fetch(
    `${config.api_server[local ? 'local' : 'online']}/${url}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      signal,
    }
  )
  .then(res => res.json())
  .catch(e => { handleError(e); return null })

  return res
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const handleError = (err:Error) => {
  if (err.name === 'AbortError') {
    //console.info('Request aborted')
    return
  }
  console.error(err)
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    layout: {
      display: 'grid',
      minHeight: '100vh',

      gridTemplateAreas: `
        "h"
        "c"
        "f"
      `,
      gridTemplateRows: 'min-content auto min-content',

      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '100vw',
      },

      // Ab LG die Desktop-Variante
      [theme.breakpoints.up('lg')]: {
        gridTemplateAreas: `
          "h h h h"
          "x n c y"
          "f f f f"
        `,
        gridTemplateColumns: 'auto 240px minmax(723px, 1000px) auto',
      },

      '&.loading': {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      },
    },

    header: {
      gridArea: 'h',
    },

    navigation: {
      gridArea: 'n',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
      //backgroundColor: theme.palette.primary.main,
      paddingLeft: '12px',
      paddingRight: '12px',
      //height: 'min-content',

      //borderRight: '1px solid black',
    },

    main: {
      gridArea: 'c',
      //paddingTop: '2rem',
      //paddingBottom: '2rem',
    },

    footer: {
      gridArea: 'f',
    },

  })
)

export default useStyles
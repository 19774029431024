/* Next */
import { useRouter } from 'next/router'

/* React */
import { useEffect, useState } from 'react'

/* Redux */
import Redux from './Navigation.Redux'

/* Functions */
import { get, post } from 'src/functions/fetch'

/* Options */
import config from 'src/config'

/* Components */
import NavigationPrimary from './NavigationPrimary'
import NavigationMenuPages from './NavigationMenuPages'
import NavigationFilterButton from './NavigationFilterButton'
import FilterTree from './NavigationFilterTree'

/* Types */
import { tMenu, item } from 'src/shared/frontendTypes/navigation'

/* Menu from preval */
import Build from './build.preval'

interface Props { placement: 'layout' | 'drawer' }

const Navigation: React.FC<Props> = ({ placement }) => {

  const [paths, setPaths] = useState<tMenu>(Build.paths)

  useEffect(() => {
    (async () => {

      const menuChanged = new Date((
        (await get({
          url: `menuTree/${process.env.NEXT_PUBLIC_MENU}`,
        })) as { changedDate:string }
      )?.changedDate)

      const buildDate = new Date(Build.buildDate as string)

      if (menuChanged < buildDate) return

      const isMenu = (await import('src/shared/frontendTypes/navigation')).isMenu
      const curentPaths = await post({
        url:`menuTree/${process.env.NEXT_PUBLIC_MENU}`, 
        body: config.shop,
      })
      if (!isMenu(curentPaths)) {
        console.error('Konnte Menü nicht richtig vom Server empfangen!', paths)
        return
      }
      setPaths(curentPaths)
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { 
    displayMenuId, setDisplayMenuId,
    displayFilter, setDisplayFilter,
    lastScrollTops, saveScrollTop,
  } = Redux()

  const router = useRouter()

  const activeMenuId = router.query.mid ? parseInt(router.query.mid as string) : null
  const activeBrandId = router.query.bid ? parseInt(router.query.bid as string) : null

  /* ------------------------------------------------------ */
  // Beim Start im Menü zu aktiven Menüpunkt springen

  const preSelectMenu = () => {

    if (activeMenuId !== null || activeBrandId !== null) {

      const getPath = (
        paths: tMenu,
        mid: number | null,
        bid: number | null,
      ): item | null => {
        for (const path of paths) {
          const { menueId, brand, children } = path
          if (mid && !bid && menueId === mid) return path
          if (bid && !mid && brand === bid) return path
          if (mid && bid && menueId === mid && brand === bid) return path
          if (children) {
            const subPath = getPath(children, mid, bid)
            if (subPath) return subPath
          }
        }
        return null
      }
      
      const path = getPath(paths, activeMenuId, activeBrandId)
      if (!path) return

      let displayMenuId = path.parent
      if (activeBrandId && !activeMenuId) displayMenuId = path.id

      setDisplayMenuId(displayMenuId)
    }
  }

  /* ------------------------------------------------------ */
  // Bei wechsel zu normalen Seiten das Menü zurücksetzen

  useEffect(() => {

    if (!router.isReady) return

    const resetMenu = (url: string) => {

      // Markenseite
      if (url.match(/^\/brands$/)) {
        setDisplayFilter(false)
      }

      // Alle Seiten außer Filter- und Produktseiten Hauptmenü
      else if (!url.match(/^(\/(m|b|p)\/.*)$/)) {
        if (displayMenuId !== 0) setDisplayMenuId(0)
        if (displayFilter === true) setDisplayFilter(false)
      }

      // Alle Seiten außer Filte-Seiten keinen Filter anzeigen
      else if (!url.match(/^(\/(m|b)\/.*)$/)) {
        if (displayFilter === true) setDisplayFilter(false)
      }

      // Auf Marken-Seiten das Marken-Menü öffnen
      else if (url.match(/^\/b\/.*$/)) {
        preSelectMenu()
        if (
          !url.match(/^\/b.+\/[\d]+\/m\/+.*[\d]$/)
          && displayFilter === true
        ) setDisplayFilter(false)
      }

    }

    const onRouteChangeStart = () => {
      saveScrollTop(router.pathname, window.scrollY)
    }

    const onRouteChangeComplete = (url: string) => {
      resetMenu(url)

      const lastScrollTop = lastScrollTops.find(x => x.url === router.pathname)

      if (lastScrollTop)
        window.scrollTo(0,lastScrollTop.y)
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayMenuId, 
    displayFilter, 
    router.isReady, router.events, router.query 
  ])

  /* ------------------------------------------------------ */
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(preSelectMenu, [])

  /* ------------------------------------------------------ */

  return <>

    { !displayFilter && [
      '/suche',
      '/m/[m_alias]/[mid]',
      '/b/[b_alias]/[bid]',
      '/b/[b_alias]/[bid]/m/[m_alias]/[mid]',
    ].includes(router.pathname) &&
      <NavigationFilterButton />
    }

    { !displayFilter && displayMenuId === 0 &&
      <NavigationPrimary />
    }

    <NavigationMenuPages 
      {...{paths, activeMenuId, activeBrandId}}
    />

    {displayFilter &&
      <FilterTree {...{placement}} />
    }

  </>

}

export default Navigation
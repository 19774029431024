import { SeoStructuredData } from 'src/SeoGlobal'

/* Next */
import Head from 'next/head'
import { AppProps } from 'next/app'

/* React */
import { useEffect } from 'react'

/* Redux */
import { Provider } from 'react-redux'
import { storeWrapper } from 'src/redux/store'

/* Funktionen */
import CheckLocalStorage from 'src/redux/CheckLocalStorage'
import GlobalDidMountFunctions from 'src/redux/GlobalDidMountFunctions'

/* Optionen */
import config from 'src/config'

/* Material */
import { ThemeProvider, Theme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from 'src/theme/theme'

/* Custom */
import Layout from 'src/layout/Layout'
import 'src/theme/global.css'

import shopInfos from 'template/infos'

/* PayPal */
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const MyApp = ({Component, ...rest}: AppProps): JSX.Element => {

  const { store, props } = storeWrapper.useWrappedStore(rest)

  useEffect(() => {
    // Material - Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles?.parentElement) jssStyles.parentElement.removeChild(jssStyles)
  }, [])

  return <>

    <Head>
      <title>{shopInfos.title}</title>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
    </Head>
    
    <SeoStructuredData />

    <ThemeProvider theme={theme}>
      
      <CssBaseline />{' ' /* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      
      <Provider store={store}>

        <PayPalScriptProvider options={config.paypalConfig}>
          <Layout>
            <Component {...props.pageProps} />
          </Layout>
        </PayPalScriptProvider>

        <CheckLocalStorage />
        <GlobalDidMountFunctions />

      </Provider>

    </ThemeProvider>

  </>
  
}

export default MyApp

/* Components */
import Loading from 'src/components/Loading'

const LoadingOverlay = () => 
  <div style={{
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    textAlign: 'center',
    verticalAlign: 'middle',
    zIndex: 9999,
  }}>
    <Loading />
  </div>

export default LoadingOverlay
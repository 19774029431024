/* Redux */
import { useSelector, useDispatch } from 'src/redux/hooks'

const Redux = () => {
  const { 
    navigationMenuDisplay,
    navigationShowFilter,
    lastScrollTops,
  } = useSelector(store => store.App)
  
  const dispatch = useDispatch()

  const setDisplayMenuId = (mid: number) =>
    dispatch({ type: 'navigationMenuChange', payload: mid })

  const setDisplayFilter = (toggle: boolean) =>
    dispatch({ type: 'navigationFilterToggle', payload: toggle })

  const resetFilter = () =>
    dispatch({ type: 'resetFilter' })

  const saveScrollTop = (url: string, y: number) =>
    dispatch({ type: 'saveScrollTop', payload: {url ,y} })

  return { 
    displayMenuId:navigationMenuDisplay, setDisplayMenuId,
    displayFilter:navigationShowFilter, setDisplayFilter,
    resetFilter,
    lastScrollTops, saveScrollTop,
  }
}

export default Redux
interface initialState {
  token: string | null
  email: string | null
}

const initialState:initialState = {
  token: null,
  email: null,
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

export type action =
  | {
      type: 'login'
      payload: {
        token: string
        email: string
      }
    }
  | {
      type: 'logout'
      payload?: undefined
    }

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const reducer = (state = initialState, {type,payload}: action): initialState => {
  switch (type) {

    /* -------------------------------------------------------------- */

    case 'login': {
      localStorage.setItem('user', JSON.stringify(payload))
      return { ...state, ...payload }
    }

    case 'logout': {
      localStorage.removeItem('user')
      return { ...state, token: null, email: null }
    }

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */

  }
}

export default reducer
/* eslint-disable @typescript-eslint/no-explicit-any */

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/*                           Allgemeine Typen                            */
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

export const isStringArray = (arg: unknown): arg is string[] => {
  return Array.isArray(arg) && arg.every(a => typeof a === 'string')
}

/**
 * Prüft typensicher ob ein `unknown` ein Objekt ist 
 * und einen `Key` besitzt
 * https://fettblog.eu/typescript-hasownproperty/
*/
export const hasOwnProperty=<
  // eslint-disable-next-line @typescript-eslint/ban-types
  X extends {},
  Y extends PropertyKey
>(obj: X, prop: Y): obj is X & Record<Y, unknown> => {
  return obj.hasOwnProperty(prop)
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Redux */
import { useSelector, useDispatch } from 'src/redux/hooks'

const Redux = () => {

  const propsSuche = useSelector(store => store.Filter.suche)

  const dispatch = useDispatch()

  const updateSuche = (suche:string) =>
    dispatch({ type: 'updateFilterSuche', payload: suche })

  const toggleMobileSuche = () =>
    dispatch({ type: 'mobileSucheToggle', payload: false })

  return { propsSuche, updateSuche, toggleMobileSuche }
  
}

export default Redux

/* Material */
import Container from '@mui/material/Container'
import Typo from '@mui/material/Typography'

import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { lighten } from '@mui/material/styles'

import { mode } from 'template/material-theme'

/* Components */
import Link from 'src/components/TypoLink'
import NoBr from 'src/components/js_elements/NoBr'

const useStyles = makeStyles((theme: Theme) => {

  const darkMode = mode === 'dark'

  const bgColor = darkMode 
    ? theme.palette.grey[800]
    : theme.palette.grey[300]

  return createStyles({

    fussnoten: {
      padding: theme.spacing(2),
      backgroundColor: bgColor,
      color: theme.palette.getContrastText(bgColor),
      '& a': {
        color: darkMode
          ? lighten(theme.palette.primary.light, 0.45)
          : theme.palette.primary.dark
        ,
      },
    },

    container: {

      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),

      '& > p': {

        lineHeight: '1rem',

        '&:not(:last-child)': {
          marginBottom: theme.spacing(2),
        },

        fontSize: '0.86rem',
        //color: theme.palette.grey[800],

      },

    },

  })
})

const Fussnoten = () => {
  const classes = useStyles()

  return <div className={classes.fussnoten}>
    <Container maxWidth='lg' className={classes.container}>

      <Typo>
        *¹ Alle Preise verstehen sich inkl. deutscher MwSt. und zzgl. Versandkosten ins
        deutsche Festland. Unter einem Bestellwert von <NoBr>49,00 €</NoBr> berechnen wir
        einen Mindermengenzuschlag in Höhe von <NoBr>5,00 €.</NoBr> Wir liefern auch ins
        Ausland. Mehr dazu können Sie im 
        Link <Link href='/auslandsversandkosten'>Auslandsversandkosten </Link> erfahren.
      </Typo>

      <Typo>
        *² Gilt für Lieferungen nach Deutschland. Wenn Sie bei uns im Rahmen
        einer Bestellung mehrere Artikel bestellen, für die unterschiedliche
        Lieferzeiten gelten, versenden wir die Ware in einer gemeinsamen
        Sendung, sofern wir mit Ihnen nichts anderes vereinbart haben. In diesem
        Fall gilt für die Warensendung insgesamt die Lieferzeit, die für den
        Artikel Ihrer Bestellung mit der längsten Lieferzeit gilt.
      </Typo>

    </Container>
  </div>
}

export default Fussnoten

/* Redux */
import { useSelector, useDispatch } from 'src/redux/hooks'

const Redux = () => {

  const warenkorbItems = Object.values(
    useSelector(store => store.Warenkorb.items)
  ).reduce((a,{qty})=>a+qty, 0)

  const wunschlisteItems = Object.values(
    useSelector(store => store.Wunschliste.items)
  ).length

  const dispatch = useDispatch()

  const toggleDrawer = () =>
    dispatch({ type: 'navigationDrawerToggle', payload: true })

  const showMobileSuche = useSelector(store => store.App.showMobileSuche)
  const toggleMobileSuche = () =>
    dispatch({ type: 'mobileSucheToggle', payload: true })

  const { token: userToken } = useSelector(store => store.User)

  return {
    toggleDrawer,
    showMobileSuche, toggleMobileSuche,
    warenkorbItems, wunschlisteItems,
    userToken,
  }
  
}

export default Redux

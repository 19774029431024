/* Next */
import Link from 'next/link'

/* Material */
import Container from '@mui/material/Container'
import Typo from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Brands from preval */
import Build from './build.preval'

/* Options */
import config from 'src/config'

/* Types */
import { tBrand } from 'src/shared/frontendTypes/brand'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    root: {
      marginTop: theme.spacing(6),
      padding: theme.spacing(6),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },

    brandList: {
      marginTop: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      flexWrap: 'wrap',

      '& img': {
        width: 100,
        height: 100,
        objectFit: 'contain',

        filter: !theme.palette.primary.contrastText.includes('0, 0, 0,')
          ? 'invert(100%)'
          : 'none'
        ,
      },

    },

    link: {
      display: 'block',
      position: 'relative',
      width: 100,
      height: 100,
    },

  })
)

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const TopMarken = () => {
  const classes = useStyles()

  const {brands} = Build

  return <div className={classes.root}>
    <Container maxWidth='lg'>
      <Typo component='div' variant='h6' align='center'>
        Unsere Top-Marken
      </Typo>
      
      <div className={classes.brandList}>
        {brands.map(brand=>(
          <Brand key={brand.id} brand={brand} />
        ))}
      </div>

    </Container>
  </div>
}

export default TopMarken

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const Brand: React.FC<{ brand: tBrand }> = ({ brand }) => {
  const classes = useStyles()

  const logo = brand.logos?.mono
  if (!logo) return null

  const logoPath = `${config.image_server}/brand/${logo.xs}`
  const href = `/b/${brand.path}/${brand.id}`

  return <Link legacyBehavior {...{href}}>
    <a 
      className={classes.link}
      title={brand.metaTitle}
    >
      <img
        src={logoPath}
        alt={brand.titel}
        width={100} height={100}
        loading='lazy'
      />
    </a>
  </Link>
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

import { z } from 'zod'
import typeguard from '../typeguard'


const itemSchema = z.object({
  id: z.number(),
  key: z.string(),
})
export type tItem = z.infer<typeof itemSchema>
export const isItem = typeguard<tItem>(itemSchema)

const itemsSchema = z.record(itemSchema)
export type tItems = z.infer<typeof itemsSchema>
export const isItems = typeguard<tItems>(itemsSchema)

/* Material */
import Container from '@mui/material/Container'
import Typo from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Timestamp from preval */
import Build from './build.preval'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingBottom: theme.spacing(2),
    },
  })
)

const Timestamp = () => {
  const classes = useStyles()

  return <Container maxWidth='lg' className={classes.root}>

    <Typo 
      id='build-timestamp'
      variant='body2' color='textSecondary' align='center'
    >
      Stand: {Build.timestamp}
    </Typo>

  </Container>
}

export default Timestamp
import { z } from 'zod'

import { discounTypeSchema } from '../discounTypes'

export const imageSchema = z.object({
  xs: z.string(),
  sm: z.string(),
  md: z.string(),
  lg: z.string(),
  xl: z.string(),
  xxl: z.string(),
  src: z.enum(['product', 'options', 'combinations']),
})
export type Image = z.infer<typeof imageSchema>

export const combinationSchema = z.object({

  // ---------------------------------------
  // Felder für den Online-Shop

  productId: z.number(),
  key: z.string(),

  brand: z.number(),
  manufacturer: z.number().optional(),
  categorie: z.number(),
  allCategories: z.array(z.number()),
  shops: z.array(z.number()),
  filter: z.array(z.number()),
  menues: z.array(z.number()).optional(),

  metaTitle: z.string(),
  metaTitleSoundex: z.array(z.string()),

  ean: z.string().optional(),
  sku: z.string().optional(),

  price: z.number(),
  deliverytime: z.number(),

  stock: z.number().optional(),

  online: z.boolean(),

  // ---------------------------------------
  // Rabatt
  discountId: z.number().optional(),
  discountPrice: z.number().optional(),
  discountTitle: z.string().optional(),
  discountType: discounTypeSchema.optional(),
  discountDateStart: z.date().optional(),
  discountDateEnd: z.date().optional(),
  discountShops: z.array(z.number()).optional(),

  // ---------------------------------------
  // Felder für die WaWi

  productTitle: z.string(),
  productOptions: z.string().optional(),
  brandTitle: z.string(),
  shippingTypeTitle: z.union([z.literal('Paket'), z.literal('Spedition')]),
  shippingDanger: z.boolean(),
  noteStock: z.string().optional(),
  customsTariffNumber: z.string().optional(),

  priceNetto: z.number(),                 // Ohne MwSt.
  priceAb: z.number().optional(),         // Der Preis der auf der AB erscheint. Abzüglich Produkt-Rabatt aber ohne Hersteller-Rabatt.
  priceEk: z.number().optional(),         // Der eingetragene EK-Preis
  priceDiscountEk: z.number().optional(), // Der EK-Preis abzüglich Produkt- und Hersteller-Rabatt
  discountRule: z.string().optional(),    // Die Produkt-Rabatt-Regel

  image: imageSchema.optional(),

  // ---------------------------------------
  // Felder für Idealo & Co.

  formerPrice:           z.number().optional(),
  paymentCosts_standard: z.number().optional(),
  paymentCosts_paypal:   z.number().optional(),

  deliveryComment:       z.string().optional(),
  deliveryCosts_dhl:     z.number().optional(),
  minimumOrderSurcharge: z.number().optional(),

  descriptionShort: z.string().optional(),
  url:              z.string().optional(),
  imageUrls:        z.array(z.string()).optional(),

  categoryPath_idealo: z.string().optional(),

  attr_size:     z.string().optional(),
  attr_color:    z.string().optional(),
  attr_gender:   z.string().optional(),
  attr_material: z.string().optional(),

})
export type Combination = z.infer<typeof combinationSchema>

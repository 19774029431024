/* Next */
import Link from 'next/link'
import { useRouter } from 'next/router'

/* Redux */
import DrawerRedux from './Drawer.Redux'
import NavigationRedux from './Navigation.Redux'

/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import useStyles from './styles'

/* Material-Icons */
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

/* Types */
import { tMenu, item } from 'src/shared/frontendTypes/navigation'

interface Props {
  paths: tMenu
  activeMenuId: number | null
  activeBrandId: number | null
}

const NavigationMenuPages: React.FC<Props> = ({ paths, activeMenuId, activeBrandId }) => {
  const classes = useStyles()
  const router = useRouter()
  const url = router.asPath

  const { 
    toggle: toggleDrawer 
  } = DrawerRedux()

  const { 
    displayMenuId, setDisplayMenuId,
    displayFilter, setDisplayFilter,
    resetFilter,
  } = NavigationRedux()

  const menuPages:JSX.Element[] = []

  /* ############################################################################ */
  const renderMenu = (
    paths:tMenu,
    mid:number,
    parent:number,
    parentBrand:item|null,
  ) => {
    const links:JSX.Element[] = []

    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
    const renderLink = ({ id, menueId, title, path, brand, children }:item) => {

      let href: string|null = null
      
      if (brand) href = `/b/${path}/${brand}`

      if (menueId) {
        href = ''
        if (parentBrand) href = `/b/${parentBrand.path}/${parentBrand.brand}`
        href += `/m/${path}/${menueId}`
      }
      if (href === url) href = null

      // Hersteller / Brands öffnen
      if (title === 'Marken') href = '/brands'

      const listItem = <ListItem 
        key={id}
        component='li' button divider 
        selected={ activeMenuId===menueId || activeBrandId===brand }
        onClick={(e:React.KeyboardEvent | React.MouseEvent)=>{

          // Unterordner öffnen
          if (children) setDisplayMenuId(id)

          // Menü öffnen
          if (path && href) {
            if (!brand) setDisplayFilter(true)
            resetFilter()
            toggleDrawer(e)
          }

        }}
      >
        {children 
          ? <ListItemIcon><ArrowDropDownIcon /></ListItemIcon>
          : <ListItemIcon><ArrowRightIcon /></ListItemIcon>
        }
        <ListItemText primary={title} />
      </ListItem>

      if (href) return <Link key={id} legacyBehavior {...{href}}>
        <a>{listItem}</a>
      </Link>

      return listItem
    }
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

    for (const path of paths) {
      const { id, brand, children } = path
      links.push(renderLink(path))
      if (children) renderMenu(
        children, 
        id, mid, 
        brand ? path : parentBrand,
      )
    }

    const backButton = <ListItem 
      component='li' button divider
      onClick={()=>{
        setDisplayMenuId(parent)
      }}
    >
      <ListItemIcon><ArrowBackIcon /></ListItemIcon>
      <ListItemText primary='Zurück' />
    </ListItem>

    if (!displayFilter && displayMenuId === mid) // Menü nur im DOM wenn sichtbar
    menuPages.push(
      <List 
        key={`menu-${mid}`}
        className={classes.list}
        /*style={{display: // Menü immer im DOM, auch wenn unsichtbar
          (!displayFilter && displayMenuId === mid ? 'block' : 'none')
        }}*/
      >

        {mid !== 0 && backButton}

        {links}

      </List>
    )
  }

  renderMenu(paths, 0, 0, null)

  return <>{menuPages}</>
}

export default NavigationMenuPages
/* Next */
import Link from 'next/link'

/* Material */
import Container from '@mui/material/Container'
import Typo from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Shop-Config */
import shopInfos from 'template/infos'

/* PayPal */
import { PayPalMarks } from '@paypal/react-paypal-js'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    wrapper: {
      backgroundColor: theme.palette.background.paper,
    },

    grid: {
      display: 'grid',
      rowGap: theme.spacing(6),
      padding: theme.spacing(6),

      [theme.breakpoints.up(640)]: {
        gridTemplateColumns: 'repeat(2, auto)',
      },
      [theme.breakpoints.up(1000)]: {
        gridTemplateColumns: 'repeat(4, auto)',
      },
    },

    column: {},

    hiddenSM: {
      [theme.breakpoints.down(639)]: {
        display: 'none',
      },
    },

    list: {},

    zahlungsart: {
      display: 'inline-block',
      width: 'auto',
      height: 34,
      marginTop: '6px',
      marginLeft: '4px',
      padding: '1px 5px',
      border: '1px solid #dcdcdc',
      borderRadius: '3px',
      whiteSpace: 'nowrap',
      color: 'black',
      backgroundColor: 'white',
      '& span': {
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: 'Arial, sans-serif',
        color: '#666',
      },
    },
    
  })
)

const InfosUndLinks = () => {
  const classes = useStyles()

  return <div className={classes.wrapper}>
    <Container maxWidth='lg' className={classes.grid}>

      <div className={classes.column}>

        <Typo component='div' variant='h6' gutterBottom>
          {shopInfos.name}
        </Typo>

        <List className={classes.list}>

          <Link href='/impressum' passHref legacyBehavior>
            <ListItemButton component='li'>
              <ListItemText primary="Impressum" />
            </ListItemButton>
          </Link>

          <Link href='/agb' passHref legacyBehavior>
            <ListItemButton component='li'>
              <ListItemText primary="AGB" />
            </ListItemButton>
          </Link>

          <Link href='/widerrufsbelehrung' passHref legacyBehavior>
            <ListItemButton component='li'>
              <ListItemText primary="Widerrufsrecht" />
            </ListItemButton>
          </Link>

          <Link href='/datenschutzerklaerung' passHref legacyBehavior>
            <ListItemButton component='li'>
              <ListItemText primary="Datenschutz" />
            </ListItemButton>
          </Link>

        </List>

      </div>

      <div className={classes.column}>

        <Typo component='div' variant='h6' gutterBottom>
          Kontakt
        </Typo>

        <List className={classes.list}>

          {shopInfos?.telephone &&
            <Link href={`tel:${shopInfos.telephone}`} passHref legacyBehavior>
              <ListItemButton component='li'>
                <ListItemText primary={`Tel.: ${shopInfos.telephone}`} />
              </ListItemButton>
            </Link>
          }
          <Link href={`mailto:${shopInfos.email}`} passHref legacyBehavior>
            <ListItemButton component='li'>
              <ListItemText primary={`Mail.: ${shopInfos.email}`} />
            </ListItemButton>
          </Link>

          <Link href='/kontakt' passHref legacyBehavior>
            <ListItemButton component='li'>
              <ListItemText primary='Kontaktformular' />
            </ListItemButton>
          </Link>

          <ListItem>
            <ListItemText primary="Mo - Fr. 09:00 bis 18:00 Uhr" />
          </ListItem>

        </List>

      </div>

      <div className={`${classes.column} ${classes.hiddenSM}`}>

        <Typo component='div' variant='h6' gutterBottom>
          Zahlungsarten
        </Typo>

        <List className={classes.list}>

          <PayPalMarks fundingSource={undefined} />
          
          {/*<ListItem className={classes.zahlungsart}>
            <ListItemText primary="Vorkasse" />
          </ListItem>*/}

        </List>

      </div>

      <div className={`${classes.column} ${classes.hiddenSM}`}>

        <Typo component='div' variant='h6' gutterBottom>
          Versandarten
        </Typo>

        <List className={classes.list}>

          <ListItem>
            <ListItemText primary="DHL für Pakete" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Möbelspedition" />
          </ListItem>

        </List>
      </div>

    </Container>
  </div>
}

export default InfosUndLinks
// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
//import { createMuiTheme } from '@mui/material/styles'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material'

import {theme as templateTheme} from 'template/material-theme'
import overrides from './overrides'

// Create a theme instance.
const theme = createMuiTheme({

  ...templateTheme,

  ...overrides,
  
})

export default theme

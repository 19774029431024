// https://material-ui.com/customization/globals/#css

import {theme} from 'template/material-theme'

/**
 * Material Standard-CSS-Regeln überschreiben
 */

const overrides: typeof theme = {
  // Style sheet name ⚛️
  
  /*MuiAccordionDetails: {
    // Name of the rule
    root: {
      // Some CSS
      display: 'block',
    },
  },*/

}

export default overrides
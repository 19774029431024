import { z } from 'zod'

import { imageSchema as dbImageSchema } from '../dbTypes/images'

export const imageSchema = dbImageSchema.omit({ 
  orig: true 
})

export type Image = z.infer<typeof imageSchema>

export const imagesSchema = z.array(imageSchema)
export type Images = z.infer<typeof imagesSchema>

export const imageSrcSchema = imageSchema.extend({
  src: z.enum(['product', 'options', 'combinations']),
})
export type imageSrc = z.infer<typeof imageSrcSchema>

export const imageSrcsSchema = z.array(imageSrcSchema)
export type imageSrcs = z.infer<typeof imageSrcsSchema>
/* JSON LD */
import { WebSite, LocalBusiness } from 'schema-dts'
import { JsonLd } from 'react-schemaorg'

import shopInfos from 'template/infos'
const {
  // name, legalname, 
  url, title, description, priceRange,
  email, telephone, fax:faxNumber, 

  street:streetAddress, 
  locality:addressLocality, postalcode:postalCode,
} = shopInfos

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

export const SeoStructuredData: React.FC = () => <>

  {/* ----------------------------------------------------------------------- */}

  <JsonLd<WebSite> item={{
    '@context': 'https://schema.org',
    '@type': 'WebSite',

    url,
    name: title,

    potentialAction: {
      '@type': 'SearchAction',
      target: `${url}suche/{search_term_string}`,
      // TODO: 'query-input': 'required name=search_term_string',
    },

  }} />

  {/* ----------------------------------------------------------------------- */}

  <JsonLd<LocalBusiness> item={{
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',

    url,
    name: title,

    slogan: description,
    priceRange,

    address: {
      '@type': 'PostalAddress',
      streetAddress,
      addressLocality,
      postalCode,
      addressCountry: 'DE',
      telephone,
      faxNumber,
    },

    telephone,
    faxNumber,
    email,

  }} />

  {/* ----------------------------------------------------------------------- */}

</>

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
import {
  emptyAdresse,
  adressField,
  adressTyp,
  storageKasse,
} from 'src/shared/frontendTypes/kasse'

import { shippingType } from 'src/shared/dbTypes/order'

import clone from 'src/functions/cloner'

type emptyAdresse = typeof emptyAdresse

const initialState = {
  timestamp: <string|false>false,

  userKontoAuswahl: '',

  adresseTyp: <adressTyp>'keine',

  shippingAdress: {
    ...emptyAdresse,
  },

  billingAdress: {
    ...emptyAdresse,
  },

  sameAdress: true,

  agbAkzeptiert: false,

  versandart: <shippingType>'dhl',
  //zahlungsart: <paymentType>'vorkasse',
}

export type tKasse = typeof initialState

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

export type action =
  | {
      type: 'restoreKasse'
      payload: storageKasse
    }
  | {
      type: 'changeUserKontoAuswahl'
      payload: string
    }
  | {
      type: 'changeKasseAdresseTyp'
      payload: adressTyp
    }
  | {
      type: 'changeKasseSameAdress'
      payload: boolean
    }
  | {
      type: 'changeKasseAdresseField'
      payload: {
        typ: string
        field: adressField
        value: string
      }
    }
  | {
      type: 'toggleAgbAkzeptiert'
      payload?: undefined
    }

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const save = (state: tKasse) => {
  setTimeout(()=>{
    localStorage.setItem('kasse', JSON.stringify(state))
  }, 0)
  return { ...state, timestamp: new Date().toISOString() }
}

const reducer = (state = initialState, {type,payload}: action): tKasse => {
  switch (type) {

    /* -------------------------------------------------------------- */

    case 'restoreKasse': {

      return save({ ...state,
        adresseTyp: payload.adresseTyp,
        shippingAdress: payload.shippingAdress,
        billingAdress: payload.billingAdress,
        sameAdress: payload.sameAdress,
        agbAkzeptiert: payload.agbAkzeptiert,
        versandart: payload.versandart,
        timestamp: new Date().toISOString(),
      })
      
    }

    /* -------------------------------------------------------------- */

    case 'changeUserKontoAuswahl': {
      return save({ ...state, userKontoAuswahl:payload })
    }

    case 'changeKasseAdresseTyp': {
      const sameAdress = payload === 'hausanschrift'
      return save({ ...state,
        adresseTyp: payload,
        sameAdress,
      })
    }

    case 'changeKasseSameAdress': {
      return save({ ...state, sameAdress:payload })
    }

    case 'changeKasseAdresseField': {
      const ident = `${payload.typ}Adress`
      if (ident === 'shippingAdress' || ident === 'billingAdress') {
        const newState = clone(state)
        const adresse = newState[ident]
        const field = payload.field
        adresse[field] = payload.value
        return save(newState)
      }
    }

    case 'toggleAgbAkzeptiert': {
      return save({ ...state, agbAkzeptiert: !state.agbAkzeptiert })
    }

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */
    
  }
}

export default reducer
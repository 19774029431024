import {
  TypedUseSelectorHook,
  useDispatch as useNativeDispatch,
  useSelector as useNativeSelector,
} from 'react-redux'
import { Dispatch } from 'redux'

import type { RootState } from './reducer'

import { action as appAction } from 'src/redux/reducers/app'
import { action as filterAction } from 'src/redux/reducers/filter'
import { action as kasseAction } from 'src/redux/reducers/kasse'
import { action as userAction } from 'src/redux/reducers/user'
import { action as warenkorbAction } from 'src/redux/reducers/warenkorb'
import { action as wunschlisteAction } from 'src/redux/reducers/wunschliste'

type action =
  | appAction
  | filterAction
  | kasseAction
  | userAction
  | warenkorbAction
  | wunschlisteAction

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useNativeDispatch<Dispatch<action>>()
export const useSelector: TypedUseSelectorHook<RootState> = useNativeSelector

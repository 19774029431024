import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Options */
import widthNav, { maxWidth } from './windowWidthNavConfig'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    drawer: {
      '& .MuiPaper-root': {
        width: '250px',
        maxWidth: '100%',
      },
    },

    list:{
      '& a, & a:visited': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    divider:{},

    mainMenuList: {[theme.breakpoints.up(maxWidth)]: {
      display: 'none',
    }},
    menuHome: {[theme.breakpoints.up(widthNav.home)]: {
      display: 'none',
    }},
    menuKonto: {[theme.breakpoints.up(widthNav.konto)]: {
      display: 'none',
    }},
    menuFavs: {[theme.breakpoints.up(widthNav.favs)]: {
      display: 'none',
    }},

    
  })
)

export default useStyles
/* React */
import { useEffect } from 'react'

/* Redux */
import { useDispatch } from 'src/redux/hooks'

/* Functions */
import isTouchDevice from 'src/functions/isTouchDevice'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const GlobalDidMountFunctions = () => {
  const dispatch = useDispatch()

  useEffect(() => {

    // Touch-Geräte erkennen
    dispatch({ type: 'isTouchDeviceToggle', payload: isTouchDevice() })

    // Coockie-Banner checken
    if (!localStorage.getItem('acceptedCookies'))
      dispatch({ type: 'showCookieBanner' })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default GlobalDidMountFunctions

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

import { z } from 'zod'
import typeguard from '../typeguard'

import { imageSrcSchema } from './images'
import { discountPriceSchema } from './product'

/*type option = {
  id: number
  name: string
}*/

const attributesSchema = z.record(z.number())
//type attributes = z.infer<typeof attributesSchema>


const attributesTextSchema = z.array(z.object({
  titel: z.string(),
  option: z.string(),
}))
//type attributesText = z.infer<typeof attributesTextSchema>


export const orderItemSchema = z.object({
  id: z.number(),
  key: z.string(),
  path: z.string(),
  name: z.string(),
  brand: z.object({
    id: z.number(),
    titel: z.string(),
  }).optional(),
  price: z.union([
    z.number(),
    discountPriceSchema,
  ]),
  qty: z.number(),
  attributes: attributesSchema.optional(),
  attributesText: attributesTextSchema.optional(),
  image: imageSrcSchema.optional(),
  deliverytime: z.number().optional(),
  stock: z.number().optional(),
  shippingType: z.number(),
  onlyStock: z.boolean().optional(),
})
export type orderItem = z.infer<typeof orderItemSchema>
export const isOrderItem = typeguard<orderItem>(orderItemSchema)


const itemSchema = z.object({
  hash: z.string(),
}).merge(orderItemSchema)
export type tItem = z.infer<typeof itemSchema>
export const isItem = typeguard<tItem>(itemSchema)


const itemsSchema = z.record(itemSchema)
export type tItems = z.infer<typeof itemsSchema>
export const isItems = typeguard<tItems>(itemsSchema)

/* Material */
import Divider from '@mui/material/Divider'

/* Redux */
import Redux from './FilterTree.Redux'

/* Components */
import Brands from './Brands'
import Filter from './Filter'

const FilterTree: React.FC = () => {
  const { filterTree, filterBrands } = Redux()

  return <>

    {filterBrands && 
      <Brands brands={filterBrands} />
    }

    {filterTree
      .filter(f=>f.options.length > 1)
      .map(filter => (
        <div key={filter.id} >
          <Filter filter={filter} />
          <Divider />
        </div>
      ))
    }

  </>

}

export default FilterTree

/**
 * Hier wird konfiguriert, ab welchen Bildschirmbreiten die
 * Navigations-Punkte von der AppBar in den Drawer verschoben
 * werden.
 */
const navConfig = {

    'home': 1200, // 1200 = 'lg'
    'konto': 500,
    'favs': 440,

} as const

//export const minWidth = Math.min(...Object.values(navConfig))
export const maxWidth = Math.max(...Object.values(navConfig))

export default navConfig
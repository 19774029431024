import { tProductPreview } from 'src/shared/frontendTypes/product'
import { tBrands } from 'src/shared/frontendTypes/brand'
import { filterProducts } from 'src/shared/frontendTypes/productsFilter'
import {
  tFilterTree,
  tFilterBrands,
} from 'src/shared/frontendTypes/filterTree'

import clone from 'src/functions/cloner'

export interface activeFilters {
  [filterId: number]: number | number[]
}

const initialState:{
  products: tProductPreview[] | null
  alternateProducts: tProductPreview[] | null
  size: number
  page: number
  count: number | null
  brands: tBrands

  filterBrands: tFilterBrands
  activeBrands: number[]

  suche: string
  filterTree: tFilterTree
  activeFilters: activeFilters

} = {

  // Produkte
  products: null,
  alternateProducts: null,
  size: 12,
  page: 1,
  count: null,

  // Hersteller
  brands: [],

  // Filter
  suche: '',
  filterTree: [],
  activeFilters: {},

  filterBrands: [],
  activeBrands: [],

}

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

type tFilter = typeof initialState

export type tFilterType = 'und' | 'oder'

export type action =
  | {
      type: 'resetFilter'
      payload?: undefined
    }
  | {
      type: 'updateFilterProducts'
      payload: filterProducts
    }
  | {
      type: 'changeFilterPage'
      payload: number
    }
  | {
      type: 'changeFilterSize'
      payload: number
    }
  | {
      type: 'updateFilterBrands'
      payload: tBrands
    }
  | {
      type: 'updateFilterSuche'
      payload: string
    }
  | {
      type: 'setFilterOption'
      payload: {
        type: tFilterType
        filter: number
        option: number
      }
    }
  | {
      type: 'unsetFilterOption'
      payload: {
        type: tFilterType
        filter: number
        option: number
      }
    }
  | {
      type: 'setBrand'
      payload: number
    }
  | {
      type: 'unsetBrand'
      payload: number
    }

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const reducer = (state = initialState, {type,payload}: action): tFilter => {

  switch (type) {

    /* -------------------------------------------------------------- */
    /*                            Produkte                            */
    /* -------------------------------------------------------------- */

    case 'updateFilterProducts':
      return { ...state,
        products: payload.products || [],
        count: payload.count || 0,
        filterTree: payload.filterTree || [],
        activeFilters: cleanActiveFilters(state.activeFilters, payload.filterTree),
        filterBrands: payload.filterBrands || [],
      }

    /* -------------------------------------------------------------- */

    case 'changeFilterPage':
      return { ...state,
        page: payload,
      }

    case 'changeFilterSize':
      return { ...state,
        size: payload,
        page: 1,
      }

    /* -------------------------------------------------------------- */
    /*                           Hersteller                           */
    /* -------------------------------------------------------------- */

    case 'updateFilterBrands':
      return { ...state,
        brands: payload,
      }

    /* -------------------------------------------------------------- */
    /*                             Filter                             */
    /* -------------------------------------------------------------- */

    case 'resetFilter':
      return {
        ...state,
        ...initialState,
        products: [],
        count: 0,
      }

    case 'updateFilterSuche':
      return { ...state,
        suche: payload,
        page: 1,
      }

    /* -------------------------------------------------------------- */

    case 'setFilterOption': 
    case 'unsetFilterOption': {

      const activeFilters = clone(state.activeFilters)
      const { type:logic, filter, option } = payload

      if (logic==='und') { // "UND"-Filter

        if (type==='setFilterOption') activeFilters[filter] = option
        else                          delete activeFilters[filter]

      } else { // "ODER"-Filter

        const activeFilter = activeFilters[filter]

        const set = new Set(Array.isArray(activeFilter)?activeFilter:[])
  
        if (type==='setFilterOption') set.add(option)
        else                          set.delete(option)
  
        const arr = [...set]
        if (arr.length > 0) activeFilters[filter] = arr
        else delete activeFilters[filter]
        
      }

      return { ...state,
        activeFilters: activeFilters,
        page: 1,
      }
      
    }

    /* -------------------------------------------------------------- */

    case 'setBrand': 
    case 'unsetBrand': {

      const brand:number = payload
      const set = new Set(
        Array.isArray(state.activeBrands)
          ? state.activeBrands
          :[]
      )

      if (type==='setBrand') set.add(brand)
      else                          set.delete(brand)
      
      const arr = [...set]

      return { ...state,
        activeBrands: arr,
        page: 1,
      }
      
    }

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */
  }
}

/**
 * Entfernt aktive Filter die nicht mehr in dem Aktuellen FilterTree sind
 */
const cleanActiveFilters = (
  activeFilters: activeFilters,
  filterTree?: tFilterTree
): activeFilters => {
  if (!filterTree) return activeFilters

  for (const activeFilter of Object.keys(activeFilters)) {

    const fid = Number(activeFilter)

    const filterTreeItem = filterTree.find(f=>f.id===fid)

    // Der ganze Filter ist nicht mehr aktiv
    if (!filterTreeItem) {
      delete activeFilters[fid]
      continue
    }

    const option = activeFilters[fid]

    // UND-FILTER Der Filter hat diese Option nicht mehr aktiv
    if ( typeof option === 'number'
      && !filterTreeItem.options.map(o=>o.id).includes(option)
    ) {
      delete activeFilters[fid]
      continue
    }

    // ODER-FILTER Der Filter hat diese Option nicht mehr aktiv
    if ( Array.isArray(option)
      && !option.every(o=>filterTreeItem.options.map(o=>o.id).includes(o))
    ) {
      activeFilters[fid] = option.filter(o =>
        filterTreeItem.options.map(o => o.id).includes(o)
      )
      continue
    }

  }

  return activeFilters
}

export default reducer
import { Theme, alpha } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Options */
import widthNav from 'src/layout/Navigation/windowWidthNavConfig'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    // ----------------------------------------------
    // AppBar

    appBar: {
      //backgroundColor: theme.palette.primary.main,
      //color: 'white'
      '& h6': {
        display: 'inline-block',
        cursor: 'pointer',
      },
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(widthNav.home)]: {
        display: 'none !important',
      },
    },

    title: { // Home-Button
      [theme.breakpoints.down(widthNav.home)]: {
        display: 'none',
      },
      '& > a': {
        color: theme.palette.getContrastText(theme.palette.primary.main.toString()),
        textDecoration: 'none',
      }
    },

    buttonFilter: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
      marginRight: theme.spacing(2),
    },

    buttonKonto: {
      [theme.breakpoints.down(widthNav.konto)]: {
        display: 'none',
      },
      '& .MuiBadge-badge': {
        padding: 0,
        '& svg': {
          maxWidth: '20px',
        }
      }
    },

    buttonFavs: {[theme.breakpoints.down(widthNav.favs)]: {
      display: 'none',
    }},

    spacer: {
      flexGrow: 1,
    },

    search: {
      position: 'relative',
      marginLeft: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      width: '350px',

      '&.full': {
        width: '100%',
        minWidth: 'inherit',
        maxWidth: 'inherit',
        '& .MuiInputBase-root': {
          width: '100%',
        },
      },
    },

    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    searchInputRoot: {
      color: 'inherit',
      width: '100%',
    },
    searchInputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}) !important`,
    },

    // ----------------------------------------------

  })
)

export default useStyles
/* Redux */
import Redux from './Navigation.Redux'

/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import useStyles from './styles'

/* Material-Icons */
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

/* Components */
import FilterTree from './FilterTree/FilterTree'
import FilterTreeAccordion from './FilterTree/FilterTreeAccordion'

interface Props {
  placement: 'layout' | 'drawer'
}

const Navigation: React.FC<Props> = ({ placement }) => {
  const classes = useStyles()

  const { setDisplayFilter } = Redux()

  return <List key={'filter'} className={classes.list}>

    <ListItem 
      component='li' button divider
      onClick={()=>setDisplayFilter(false)}
    >
      <ListItemIcon><ArrowBackIcon /></ListItemIcon>
      <ListItemText primary='Zurück' />
    </ListItem>

    {placement === 'drawer' && <FilterTree />}
    {placement === 'layout' && <FilterTreeAccordion />}

  </List> 
}

export default Navigation
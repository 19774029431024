/* Redux */
import { useSelector, useDispatch } from 'src/redux/hooks'

/* Types */
import { tFilterType } from 'src/redux/reducers/filter'

const Redux = () => {
  const dispatch = useDispatch()

  const { 
    filterTree, activeFilters,
    filterBrands, activeBrands,
  } = useSelector(store => store.Filter)

  const changeFilter = (
    type: tFilterType,
    toggle: boolean,
    filter: number,
    option: number
  ) =>
    dispatch({
      type: toggle ? 'setFilterOption' : 'unsetFilterOption',
      payload: { type, toggle, filter, option },
    })

  const toggleBrand = (toggle:boolean, id:number) =>
    dispatch({
      type: toggle ? 'setBrand' : 'unsetBrand',
      payload: id,
    })

  return { 
    filterTree, activeFilters, changeFilter,
    filterBrands, activeBrands, toggleBrand,
  }
}

export default Redux

import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    headerWrapper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    topContainer: {},

  })
)

export default useStyles
/* Redux */
import Redux from './Drawer.Redux'

/* Material */
import Drawer from '@mui/material/Drawer'
import useStyles from './styles'

/* Components */
import Navigation from './Navigation'

const NavigationDrawer = () => {
  const classes = useStyles()

  const { open, toggle } = Redux()

  return <Drawer
    className={classes.drawer}
    variant='temporary'
    anchor='left'
    open={open}
    onClose={toggle}
    aria-hidden='true'
  >
    <Navigation placement='drawer' />
  </Drawer>
}

export default NavigationDrawer
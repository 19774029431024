/* eslint-disable */
// https://github.com/airbnb/is-touch-device/blob/224506a94d64d478aae65d25f184a4405339f453/src/index.js#L1

const isTouchDevice = () => (
    !!(typeof window !== 'undefined' &&
        ('ontouchstart' in window ||
            (window.DocumentTouch &&
                typeof document !== 'undefined' &&
                document instanceof window.DocumentTouch))) ||
    !!(typeof navigator !== 'undefined' &&
        (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
)

export default isTouchDevice
/* Material */
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Material-Icons */
import LoopIcon from '@mui/icons-material/Loop'

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    rotateIcon: {
      animation: 'spin 4s linear infinite',
    },
  })
)

const LoadingIcon = () => {
  const classes = useStyles()

  return <>
    <LoopIcon className={classes.rotateIcon} />
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </>
}

export default LoadingIcon
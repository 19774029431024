import { z } from 'zod'
import typeguard from './typeguard'

export const shops = {

  // Web-Shops
  101: {
    name: "Homey Love",
    icon: 'homeyloveNeu.png',
    url: 'https://www.homeylove.de/',
  },
  201: {
    name: "Angelina Minardi",
    icon: 'angelinaminardi.png',
    url: 'https://shop.angelinaminardi.de/',
  },
  202: {
    name: "liebeszuhause",
    icon: 'liebeszuhause.png',
    url: 'https://www.liebeszuhause.de/',
  },

  // Marketplaces
  1001: {
    name: "Amazon",
    icon: 'amazon.png',
    url: null,
  },
  1002: {
    name: "eBay",
    icon: 'ebay.png',
    url: null,
  },

}

const shopSchema = z.union([
  z.literal(101),
  z.literal(201),
  z.literal(202),
  z.literal(1001),
  z.literal(1002),

])
//const shopSchema = z.literal(101)
export type shopId = z.infer<typeof shopSchema>
export const isShopId = typeguard<shopId>(shopSchema)
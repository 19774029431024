import { tItem } from 'src/shared/frontendTypes/wunschliste'
import { tProductPreview } from 'src/shared/frontendTypes/product'

import clone from 'src/functions/cloner'

type items = { [column: string]: tItem }
const items: items = {}

const products: tProductPreview[] = []

const initialState = {

  // Daten der Wunschliste
  timestamp: <string|false>false,
  items,

  // Aktuelle Produkt-Daten
  products,

}

export type tWunschliste = typeof initialState

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

export type action =
  | {
      type: 'restoreWunschlisteItems'
      payload: items
    }
  | {
      type: 'addWunschlisteItem'
      payload: tItem
    }
  | {
      type: 'removeWunschlisteItem'
      payload: string
    }
  | {
      type: 'updateWunschlisteProducts'
      payload: tProductPreview[]
    } 

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const save = (state: tWunschliste) => {
  setTimeout(()=>{
    localStorage.setItem('wunschliste', JSON.stringify(state))
  }, 0)
  return { ...state, timestamp: new Date().toISOString() }
}

const reducer = (state = initialState, {type,payload}: action): tWunschliste => {
  switch (type) {

    /* -------------------------------------------------------------- */
    /*                     Daten der Wunschliste                      */
    /* -------------------------------------------------------------- */

    case 'restoreWunschlisteItems': {
      return save({ ...state,
        items: payload,
        timestamp: new Date().toISOString(),
      })
    }

    /* -------------------------------------------------------------- */

    case 'addWunschlisteItem': {
      const items = clone(state.items)
      const item = payload
      const { id, key } = item

      items[key] = { id, key }

      return save({ ...state,
        items,
      })
    }

    /* -------------------------------------------------------------- */

    case 'removeWunschlisteItem': {
      const items = clone(state.items)
      delete items[payload]
      return save({ ...state,
        items,
      })
    }

    /* -------------------------------------------------------------- */
    /*                    Aktuelle Produkt-Daten                      */
    /* -------------------------------------------------------------- */

    case 'updateWunschlisteProducts':
      return { ...state,
        products: payload || [],
      }

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */
    
  }
}

export default reducer
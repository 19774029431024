/* Material */
import Container from '@mui/material/Container'
import Typo from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Shop-Config */
import shopInfos from 'template/infos'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
)

const Copyright = () => {
  const classes = useStyles()

  return <Container maxWidth='lg' className={classes.root}>

    <Typo variant='body2' color='textSecondary' align='center'>
      Copyright ©{shopInfos.publicher} {new Date().getFullYear()}
    </Typo>

  </Container>
}

export default Copyright
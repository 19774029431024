import { z } from 'zod'
import typeguard from '../typeguard'

import { imageSrcSchema } from '../frontendTypes/images'
import { shopId, isShopId } from '../shops'
//import { OnApproveData } from '@paypal/paypal-js/types/components/buttons'

import { productSchema } from './product'
import { combinationSchema } from './combination'

export const shippingTypeSchema = z.literal('dhl')
export type shippingType = z.infer<typeof shippingTypeSchema>


export const paymentTypeSchema = process.env.NODE_ENV === 'test'
  ? z.enum([
    'vorkasse',
    'paypal',
    'none',
  ])
  : z.enum([
    //'vorkasse',
    'paypal',
    'none',
  ])
export type paymentType = z.infer<typeof paymentTypeSchema>


export const adresseSchema = z.object({
  firma: z.string().optional(),
  anrede: z.string().optional(),
  vorname: z.string(),
  nachname: z.string(),
  strasse: z.string(),
  nr: z.string(),
  adresse2: z.string().optional(),
  plz: z.string(),
  stadt: z.string(),
  land: z.string(),
  email: z.string(),
  telefon: z.string().optional(),
  
  kundennr: z.string().optional(),
  postnummer: z.string().optional(),
  packstation: z.string().optional(),
  postfiliale: z.string().optional(),
})
export type Adresse = z.infer<typeof adresseSchema>

export const adresseShippingSchema = adresseSchema.extend({
  vorname: z.string().optional(),
  nachname: z.string().optional(),
  strasse: z.string().optional(),
  nr: z.string().optional(),
  email: z.string().optional(),
})
export type AdresseShipping = z.infer<typeof adresseShippingSchema>


export const attributesSchema = z.record(z.number())
export type attributes = z.infer<typeof attributesSchema>


export const attributesTextSchema = z.array(z.object({
  titel: z.string(),
  option: z.string(),
}))
export type attributesText = z.infer<typeof attributesTextSchema>


export const itemSchema = z.object({
  id: z.number(),
  key: z.string(),
  path: z.string(),
  name: z.string(),
  brand: z.object({
    id: z.number(),
    titel: z.string(),
  }).optional(),
  price: z.number(),
  qty: z.number(),
  attributes: attributesSchema.optional(),
  attributesText: attributesTextSchema.optional(),
  image: imageSrcSchema.optional(),
  deliverytime: z.number().optional(),
  shippingType: z.number(),
  stock: z.number().optional(),
  hash: z.string().optional(),

  product: productSchema.optional(),
  combination: combinationSchema.optional(),
})
export type Item = z.infer<typeof itemSchema>


export const warenkorbSchema = z.array(itemSchema)
export type Warenkorb = z.infer<typeof warenkorbSchema>


export const totalsSchema = z.object({
  WarenkorbBrutto: z.number(),
  WarenkorbNetto: z.number(),
  WarenkorbEnthalteneMwSt: z.number(),
  
  Versandkosten: z.number(),
  Mindermengenzuschlag: z.number().optional(),
  EnthalteneMwSt: z.number(),
  Gesamtsumme: z.number(),
})
export type Totals = z.infer<typeof totalsSchema>


export const OnApproveDataSchema = z.object({
  billingToken: z.string().nullable().optional(),
  facilitatorAccessToken: z.string(),
  orderID: z.string(),
  payerID: z.string().optional(),
  paymentID: z.string().nullable().optional(),
  subscriptionID: z.string().optional(),
  authCode: z.string().optional(),
})

export const shippingAdressType = z.enum([
  'hausanschrift',
  'geschaeft',
  'packstation',
  'postfiliale',
]).default('hausanschrift')
export type ShippingAdressType = z.infer<typeof shippingAdressType>

export const orderSchema = z.object({
  shop: z.custom<shopId>((val)=>isShopId(val)),

  billingAdress: adresseSchema,
  shippingAdress: adresseShippingSchema.optional(),
  shippingAdressType,

  zahlungsart: paymentTypeSchema,
  versandart: shippingTypeSchema,

  warenkorb: warenkorbSchema,
  totals: totalsSchema,

  payPalInfos: OnApproveDataSchema.optional(),
})
export type Order = z.infer<typeof orderSchema>
export const isOrder = typeguard<Order>(orderSchema)

export const ordersSchema = z.array(orderSchema)
export type Orders = z.infer<typeof ordersSchema>
export const isOrders = typeguard<Orders>(ordersSchema)


export const statusSchema = z.enum(['open', 'confirmed', 'cancelled'])
export type status = z.infer<typeof statusSchema>
export const isStatus = typeguard<status>(statusSchema)


export const OrderSchemaSchema = orderSchema.extend({
  id: z.number(),
  user: z.string().optional(),
  auftragNr: z.number().optional(),
  email: z.string(),
  price: z.number(),
  note: z.string().optional(),
  orderDate: z.date(),
  token: z.string(),
  status: statusSchema,
  paypalId: z.string().optional(),

  created: z.date(),
  changed: z.date(),
})
export type OrderSchema = z.infer<typeof OrderSchemaSchema>
export const isOrderSchema = typeguard<OrderSchema>(OrderSchemaSchema)

export const OrdersSchemaSchema = z.array(OrderSchemaSchema)
export type OrdersSchema = z.infer<typeof OrdersSchemaSchema>
export const isOrdersSchema = typeguard<OrdersSchema>(OrdersSchemaSchema)


export const orderViewSchema = OrderSchemaSchema.pick({
  id: true,
  billingAdress: true,
  shippingAdress: true,
  shippingAdressType: true,
  orderDate: true,
  price: true,
  versandart: true,
  zahlungsart: true,
  warenkorb: true,
}).extend({
  orderDate: z.string(),
})
export type orderView = z.infer<typeof orderViewSchema>
export const isOrderView = typeguard<orderView>(orderViewSchema)

export const orderViewsSchema = z.array(orderViewSchema)
export type orderViews = z.infer<typeof orderViewsSchema>
export const isOrderViews = typeguard<orderViews>(orderViewsSchema)

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
/* Requests */

const orderResponseSchema = z.object({
  id: z.number(),
  token: z.string(),
})
export type orderResponse = z.infer<typeof orderResponseSchema>
export const isNewOrderResponse = typeguard<orderResponse>(orderResponseSchema)


export const orderTokenRequestSchema = z.object({
  id: z.number(),
  token: z.string(),
})
export type orderRequest = z.infer<typeof orderTokenRequestSchema>
export const isOrderTokenRequest = typeguard<orderRequest>(orderTokenRequestSchema)

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
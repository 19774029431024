/* React */
//import { useState } from 'react'

/* Redux */
import Redux from './FilterTree.Redux'

/* Material */
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typo from '@mui/material/Typography'
import useStyles from './FilterTree.styles'

/* Material-Icons */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

/* Components */
import Brands from './Brands'
import Filter from './Filter'


const FilterTree: React.FC = () => {
  const { filterTree, filterBrands } = Redux()

  /*const [expanded, setExpanded] = useState<string|false>(false)
  const handleChange = (panel: string) => 
    setExpanded(panel === expanded ? false : panel)*/

  // ------------------------------------------------------------
  // Eine bestimme Anzahl an Filteroptionen immer vor aufgeklappt
  const maxFirstDisplayOptions = 30

  let expandedFilters = 0, i = 0

  i += filterBrands.length
  if (i <= maxFirstDisplayOptions) expandedFilters++

  for (const filter of filterTree) {
    i += 2 // Für den Platz vom Titel
    i += filter.options.length
    if (i > maxFirstDisplayOptions) break
    expandedFilters++
  }
  // ------------------------------------------------------------

  return <div> 
    {filterBrands && filterBrands.length > 0 &&
      <AccordionItem
        name="brands"
        title="Marken"
        body={<Brands brands={filterBrands} inAccordion />}
        //{...{ expanded, handleChange }}
        defaultExpanded={expandedFilters > 0}
      />
    }
    {filterTree
      .filter(f=>f.options.length > 1)
      .map((filter,i) => (
        <AccordionItem
          key={filter.id}
          name={filter.name}
          title={filter.title}
          body={<Filter filter={filter} inAccordion />}
          //{...{ expanded, handleChange }}
          defaultExpanded={++i < expandedFilters}
        />
      ))
    }
  </div>
}

interface AccordionItemProps {
  name: string
  title: string
  body: JSX.Element
  //expanded: string | false
  //handleChange: (panel: string) => void
  defaultExpanded: boolean
}

const AccordionItem: React.FC<AccordionItemProps> = ({ 
  name, title, body, defaultExpanded //expanded, handleChange 
}) => {
  const classes = useStyles()

  return <Accordion
    key={name+defaultExpanded}
    className={classes.filterAccordion}
    TransitionProps={{ unmountOnExit: true }}
    square
    //expanded={expanded === `panel-${name}`}
    //onChange={()=>{handleChange(`panel-${name}`)}}
    {...{defaultExpanded}}
  >

    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel-${name}-content`}
      id={`panel-${name}-header`}
    >
      <Typo>{title}</Typo>
    </AccordionSummary>

    <AccordionDetails>{body}</AccordionDetails>

  </Accordion>
}

export default FilterTree

/* Next */
import Link from 'next/link'

/* Material */
import MuiLink from '@mui/material/Link'

interface Props {
  href: string
  children: React.ReactNode
}

const TypoLink = ({ href, children }: Props) => 
  <Link href={href} passHref legacyBehavior>
    <MuiLink underline='hover'>
      {children}
    </MuiLink>
  </Link>

export default TypoLink
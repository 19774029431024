/* Material */
import Container from '@mui/material/Container'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Material-Icons */
import LoopIcon from '@mui/icons-material/Loop'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      paddingTop: theme.spacing(6),
    },
    rotateIcon: {
      animation: 'spin 4s linear infinite',
      fontSize: '6rem',
    },
  })
)

const LoadingIcon = () => {
  const classes = useStyles()

  return <Container 
    maxWidth='lg'
    className={classes.root} 
  >
    <LoopIcon 
      className={classes.rotateIcon} 
      fontSize='large'
    />
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </Container>
}

export default LoadingIcon
/* Next */
import { useRouter } from 'next/router'

/* React */
import { useState, useEffect } from 'react'

/* Redux */
import SucheRedux from './Suche.Redux'
import NavigationRedux from 'src/layout/Navigation/Navigation.Redux'

/* Material */
import InputBase from '@mui/material/InputBase'
import useStyles from './styles'

/* Material-Icons */
import SearchIcon from '@mui/icons-material/Search'

/* Functions */
import { forbiddenSearchChars } from 'src/functions/regex'

/* Components */
import LoadingIcon from 'src/components/LoadingIcon'

interface Props {
  autoFocus?: boolean
  fullWidth?: boolean
}

let AmTippen: ReturnType<typeof setTimeout>

const Suche: React.FC<Props> = ({autoFocus, fullWidth}) => {
  const router = useRouter()

  const classes = useStyles()

  const { propsSuche, updateSuche, toggleMobileSuche } = SucheRedux()
  const { setDisplayFilter } = NavigationRedux()

  const [sucheEingabe, updateSucheEingabe] = useState<string>(propsSuche)
  const [amSuchen, toggleAmSuchen] = useState<boolean>(false)

  const handleChange = (suche: string): void => {
    const savedSuche = saveSuche(suche)

    updateSucheEingabe(savedSuche)
    toggleAmSuchen(true)

    if (router.pathname !== '/suche') router.push('/suche')

    clearTimeout(AmTippen); AmTippen = setTimeout(() => {
      updateSuche(savedSuche)
      setDisplayFilter(true)
      toggleAmSuchen(false)
    }, 400)
  }

  useEffect(() => {
    const propsSuche = router.query.suche as string
    if (propsSuche) updateSuche(saveSuche(propsSuche))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[router.query.suche])

  const displaySucheEingabe = propsSuche === sucheEingabe || amSuchen

  return <div className={[
    classes.search,
    fullWidth ? 'full' : null
  ].join(' ')}>

    <div className={classes.searchIcon}>
      {!amSuchen ? <SearchIcon />
                 : <LoadingIcon />}
    </div>

    <InputBase
      id="AppBar-Search-Input"
      placeholder="Wonach suchen Sie?"
      classes={{
        root: classes.searchInputRoot,
        input: classes.searchInputInput,
      }}
      value={displaySucheEingabe ? sucheEingabe : ''}
      onChange={({target:{value}})=>handleChange(value)}
      inputProps={{ 'aria-label': 'search' }} type={'search'}
      autoFocus={autoFocus}
      onBlur={toggleMobileSuche}

      // Bei Enter die Virtuelle Tastatur verbergen
      onKeyDown={(e)=>{
        const { key } = e
        if (key === 'Enter') {
          e.preventDefault()
          const element = document.getElementById('AppBar-Search-Input')
          if (!element) return
            element.blur()
        }
      }}
      
    />

  </div>
}

export default Suche

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const saveSuche = (suche: string) => suche
  .substring(0, 255)
  .replace(forbiddenSearchChars, '')

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
import { z } from 'zod'
import typeguard from '../typeguard'

import { shippingTypeSchema, shippingAdressType } from '../dbTypes/order'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const adresseSchema = z.object({
  firma: z.string().default(''),
  name: z.string().default(''),
  anrede: z.string().default(''),
  vorname: z.string().default(''),
  nachname: z.string().default(''),
  strasse: z.string().default(''),
  nr: z.string().default(''),
  adresse2: z.string().default(''),
  plz: z.string().default(''),
  stadt: z.string().default(''),
  land: z.string().default('DE'),
  email: z.string().default(''),
  telefon: z.string().default(''),

  kundennr: z.string().default(''),
  postnummer: z.string().default(''),
  packstation: z.string().default(''),
  postfiliale: z.string().default(''),
})
export type tAdresse = z.infer<typeof adresseSchema>
export const isAdresse = typeguard<tAdresse>(adresseSchema)

export const emptyAdresse: tAdresse = adresseSchema.parse({})

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

export type shippingOrBilling = 'shipping' | 'billing'

export type adressField = keyof typeof emptyAdresse

const adressTypSchema = shippingAdressType.or(z.literal('keine'))
export type adressTyp = z.infer<typeof adressTypSchema>

export type anreden = 
  | 'Herr'
  | 'Frau'
  | '*'
  | 'Firma'

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

const storageKasseSchema = z.object({
  adresseTyp: adressTypSchema,
  shippingAdress: adresseSchema,
  billingAdress: adresseSchema,
  sameAdress: z.boolean(),
  agbAkzeptiert: z.boolean(),
  versandart: shippingTypeSchema,
})
export type storageKasse = z.infer<typeof storageKasseSchema>
export const isStorageKasse = typeguard<storageKasse>(storageKasseSchema)

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
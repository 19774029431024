/* Redux */
import { useSelector, useDispatch } from 'src/redux/hooks'

/* Material */
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'

import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

/* Next */
import Link from 'next/link'

/* Shop-Config */
import shopInfos from 'template/infos'

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      '& p': {
        margin: 0,
      },
      '& a': {
        color: 'inherit',
      },
    },
  })
)

const message =
  <p>
    {shopInfos.name} verwendet Cookies, um Ihren Online-Einkauf und Ihr
    persönliches Shoppingerlebnis so angenehm wie möglich zu gestalten. Wenn Sie
    unsere Services weiterhin nutzen, gehen wir davon aus, dass Sie der
    Verwendung von Cookies zustimmen.
    {' '} <Link href="/datenschutzerklaerung">Hier</Link> finden Sie weitere
    Informationen zu Cookies und wie Sie sie ablehnen.
  </p>

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
} as const

const CookieBanner = () => {
  const classes = useStyles()
  const open = useSelector(store => store.App.showCookieBanner)
  const dispatch = useDispatch()
  
  const action = 
  <Button 
    color='secondary' size='small' variant='contained'
    onClick={()=>dispatch({type:'acceptCookieBanner'})}
  >
    Ich stimme zu
  </Button>

  return <Snackbar
    className={classes.root}
    {...{
      anchorOrigin,
      open,
      message,
      action,
    }}
  />
}

export default CookieBanner

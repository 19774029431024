import { z } from 'zod'
import { imagesSchema } from './images'


export const optionSchema = z.object({
  id: z.number(),
  name: z.string(),
  filter: z.array(z.number()).nullable(),
  images: imagesSchema.optional(),
  uncultivable: z.boolean().optional(), 
  //uncultivable: z.literal().optional(),
  order: z.number().default(0),
})
export type Option = z.infer<typeof optionSchema>


export const attributeSchema = z.object({
  id: z.number(),
  name: z.string(),
  options: z.array(optionSchema),
  type: z.string().default('other'),
  order: z.number().default(0),
})
export type Attribute = z.infer<typeof attributeSchema>


export const combinationSchema = z.object({
  key: z.string(),
  attributes: z.array(z.string()),

  price: z.number(),
  ek: z.number().optional(),

  ean: z.string().optional(),
  sku: z.string().optional(),
  asin: z.string().optional(),
  idealo: z.string().optional(),

  deliverytime: z.number(),
  options: z.array(z.number()).optional(),
  filter: z.array(z.number()),
  images: imagesSchema.optional(),

  weight: z.number().optional(),
  vpe: z.number().optional(),

  cultivable: z.boolean(),
})
export type Combination = z.infer<typeof combinationSchema>


export const crossSellSchema = z.object({
  titel: z.string(),
  products: z.array(z.object({
    id: z.number(),
    options: z.object({
      filter: z.array(z.number()),
      preselect: z.array(z.number()),
    }),
  })),
})
export type CrossSell = z.infer<typeof crossSellSchema>


export const productSchema = z.object({
  id: z.number(),
  brand: z.number(),
  manufacturer: z.number(),
  categorie: z.number(),
  allCategories: z.array(z.number()),
  ordered: z.number(),
  priority: z.number(),
  shippingType: z.number(),

  customsTariffNumber: z.string().optional(),

  images: imagesSchema.optional(),

  attributes: z.array(attributeSchema),
  combinations: z.array(combinationSchema),

  name: z.string(),
  metaTitle: z.string(),
  alternativeTitle: z.string().optional(),
  description: z.string().optional(),
  descriptionDetails: z.string().optional(),
  descriptionShort: z.string().optional(),
  path: z.string().optional(),

  note: z.string().optional(),
  noteStock: z.string().optional(),

  online: z.boolean(),
  onlyStock: z.boolean(),
  danger: z.boolean().optional(),
  shippingDanger: z.boolean().optional(),

  shops: z.array(z.number()),
  shopsFixed: z.boolean().optional(),
  filter: z.array(z.number()),
  menues: z.array(z.number()).optional(),

  manufacturerDiscountRule: z.string().optional(),

  crossSell: crossSellSchema.optional(),

  created: z.date(),
  changed: z.date(),
  imported: z.literal(true).optional(),

  deleted: z.literal(true).optional(),
})
export type Product = z.infer<typeof productSchema>

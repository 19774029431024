import { z } from 'zod'


export const imageColorInformationSchema = z.object({
  r: z.number(),
  g: z.number(),
  b: z.number(),
  a: z.number(),
})
export type imageColorInformation = z.infer<typeof imageColorInformationSchema>

export const imageSizesSchema = z.enum(['xs', 'sm', 'md', 'lg', 'xl', 'xxl'])
export type imageSizes = z.infer<typeof imageSizesSchema>

export const imageSchema = z.object({
  orig: z.string(),
  xs: z.string(),
  sm: z.string(),
  md: z.string(),
  lg: z.string(),
  xl: z.string(),
  xxl: z.string(),
  type: z.enum(['item', 'color', 'mood']).optional(),
  color: imageColorInformationSchema.optional(),
})
export type Image = z.infer<typeof imageSchema>

export const imagesSchema = z.array(imageSchema)
export type Images = z.infer<typeof imagesSchema>

export const imageSrcSchema = imageSchema.extend({
  src: z.enum(['product', 'options', 'combinations']),
})
export type imageSrc = z.infer<typeof imageSrcSchema>

export const imageSrcsSchema = z.array(imageSrcSchema)
export type imageSrcs = z.infer<typeof imageSrcsSchema>
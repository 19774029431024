
import clone from 'src/functions/cloner'

type scrollTops = {
  url: string
  y: number
}[]

const lastScrollTops: scrollTops = []

const initialState = {

  showNavigationDrawer: false,
  navigationMenuDisplay: 0,
  navigationShowFilter: false,
  showMobileSuche: false,
  isTouchDevice: false,
  loading: false,

  showCookieBanner: false,

  lastScrollTops,

}

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

export type action =
  | {
      type: 'navigationDrawerToggle'
      payload: boolean
    }
  | {
      type: 'navigationMenuChange'
      payload: number
    }
  | {
      type: 'navigationFilterToggle'
      payload: boolean
    }
  | {
      type: 'mobileSucheToggle'
      payload: boolean
    }
  | {
      type: 'isTouchDeviceToggle'
      payload: boolean
    }
  | {
      type: 'toggleLoading'
      payload: boolean
    }
  | {
      type: 'showCookieBanner'
      payload?: undefined
    }
  | {
      type: 'acceptCookieBanner'
      payload?: undefined
    }
  | {
      type: 'saveScrollTop'
      payload: {
        url: string,
        y: number,
      }
    }

/* +++++++++++++++++++++++++++++++++++++++++++++++++ */

const reducer = (state = initialState, {type,payload}: action): typeof initialState => {
  switch (type) {

    /* -------------------------------------------------------------- */

    case 'navigationDrawerToggle':
      return { ...state,
        showNavigationDrawer: payload,
      }

    /* -------------------------------------------------------------- */

    case 'navigationMenuChange':
      return { ...state,
        navigationMenuDisplay: payload,
      }

    case 'navigationFilterToggle':
      return { ...state,
        navigationShowFilter: payload,
      }

    /* -------------------------------------------------------------- */

    case 'mobileSucheToggle':
      return { ...state,
        showMobileSuche: payload,
      }

    /* -------------------------------------------------------------- */

    case 'isTouchDeviceToggle':
      return { ...state,
        isTouchDevice: payload,
      }

    /* -------------------------------------------------------------- */

    case 'toggleLoading':
      return { ...state,
        loading: payload,
      }

    /* -------------------------------------------------------------- */

    case 'showCookieBanner':
      return { ...state, showCookieBanner: true }

    case 'acceptCookieBanner':
      setTimeout(()=>{
        localStorage.setItem('acceptedCookies', JSON.stringify({
          date: new Date()
        }))
      }, 0)
      return { ...state, showCookieBanner: false }

    /* -------------------------------------------------------------- */

    case 'saveScrollTop':
      const lastScrollTops = clone(state.lastScrollTops)
      const index = lastScrollTops.findIndex(x=>x.url === payload.url)

      if (index !== -1) lastScrollTops[index] = payload
      else lastScrollTops.push(payload)

      if (lastScrollTops.length > 10) lastScrollTops.shift()

      return { ...state,
        lastScrollTops,
      }

    /* -------------------------------------------------------------- */

    default: return state

    /* -------------------------------------------------------------- */

  }
}

export default reducer
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    
    // ----------------------------------------------

    filter: {

    },

    filterListIcon: {
      minWidth: 30
    },

    filterAccordion: {
      boxShadow: 'none',
      '& > .MuiCollapse-root': {
        '& .MuiAccordionDetails-root': {
          padding: 0,
        }
      },

      '&.Mui-expanded': {
        borderTop: '1px solid',
        borderColor: theme.palette.divider,
      },
    },

    // ----------------------------------------------

  })
)

export default useStyles

/* Next */
//import Image from 'next-image-export-optimizer'

/* React */
import { useState, useEffect, useRef } from 'react'

/* Components */
import AppBar from './AppBar'

/* Template */
import HeaderTop from 'template/header/Header'

const Header: React.FC = () => {

  const [headerHeight, setHeaderHeight] = useState(100)
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (headerRef === null) return
    if (headerRef.current === null) return
    setHeaderHeight(headerRef.current.clientHeight)
  },[])

  return <>

    <div ref={headerRef}>
      <HeaderTop />
    </div>

    <AppBar headerHeight={headerHeight} />

  </>
}

export default Header

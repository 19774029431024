/* Redux */
import { useSelector } from 'src/redux/hooks'

/* Material */
import useStyles from './styles'

/* Components */
import Header from './Header/Header'
import Navigation from './Navigation/Navigation'
import NavigationDrawer from './Navigation/Drawer'
import Footer from './Footer/Footer'
import CookieBanner from './CookieBanner'
import Loading from './Loading'

const Layout: React.FC<{children?: React.ReactNode}> = ({ children }) => {
  const classes = useStyles()

  const loading = useSelector(store => store.App.loading)

  return <div className={[
    classes.layout,
    loading ? 'loading' : null
  ].join(' ')}>

    <div className={classes.header}>
      <Header />
    </div>

    <nav className={classes.navigation}> {/* TODO: Lighthouse Mobile */}
      <Navigation placement='layout' />
    </nav>

    <NavigationDrawer />

    <main className={classes.main}>
      {children}
    </main>

    <footer className={classes.footer}>
      <Footer />
    </footer>

    <CookieBanner />

    {loading && <Loading />}

  </div>
}

export default Layout

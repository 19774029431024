/* Redux */
import Redux from './Navigation.Redux'

/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import useStyles from './styles'

/* Material-Icons */
import TuneIcon from '@mui/icons-material/Tune'

const Navigation: React.FC = () => {
  const classes = useStyles()

  const { setDisplayFilter } = Redux()

  return <List key={'filter'} className={classes.list}>

    <ListItem 
      component='li' button divider
      onClick={()=>setDisplayFilter(true)}
    >
      <ListItemIcon><TuneIcon /></ListItemIcon>
      <ListItemText primary='Filter' />
    </ListItem>

  </List> 

}

export default Navigation

/**
 * Alle Zeichen die nicht in ein Namen-Feld engetippt werden dürfen
*/
export const forbiddenNameChars = /[^a-z0-9-äöüßáćéǵíḱĺḿńóṕŕśúǘẃýź\ \.]/gmi

/**
 * Alle Zeichen die nicht in ein Suche-Feld engetippt werden dürfen
*/
export const forbiddenSearchChars = /[^a-z0-9-äöüßáćéǵíḱĺḿńóṕŕśúǘẃýź\ \+\"\(\)!\.]/gmi

/**
 * Alle Zeichen die in einm Namen enthalten sein dürfen
*/
export const nameChars = /^[a-z-äöüßáćéǵíḱĺḿńóṕŕśúǘẃýź\ ]+$/gmi

/**
 * Wandelt einen Titel / Freien Text sicher um
 * in einen maschienenlesbaren sicheren string
 */
export const maschinenName = (titel: string): string =>{
  let name = titel.toLocaleLowerCase()

  // Deutsche Umlaute ersetzen
  name = name.replace(/[\æ\ä]/gim, 'ae')
  name = name.replace(/[\œ\ö]/gim, 'oe')
  name = name.replace(/[\ü]/gim, 'ue')
  name = name.replace(/[\ß]/gim, 'ss')

  name = name.replace(/[\&]/gim, 'and')
  name = name.replace(/[\@]/gim, '-at-')

  // Alle Akzente "accents/diacritics" ersetzen
  name = name.normalize('NFD').replace(/\p{Diacritic}/gu, '')

  // ALLES was noch übrig bleit raus schmeißen
  name = name.replace(/[^a-z0-9_-]/gim, '-')

  return name
}

/**
 * Prüft einen URL-String ob dieser was als URL taugt
 * und passt diesen dann automatisch an
 */
export const saveUrl = (unsaveUrl: string):string => 
  unsaveUrl.replace(/[^a-z0-9_-]/gmi, '-')
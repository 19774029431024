/* Components */
import TopMarken from './TopMarken'
import InfosUndLinks from './InfosUndLinks'
import Copyright from './Copyright'
import Timestamp from './Timestamp'
import Fussnoten from './Fussnoten'

const Footer = () => <>
  <TopMarken />
  <InfosUndLinks />
  <Copyright />

  { process.env.NEXT_PUBLIC_TIMESTAMP && 
    <Timestamp />
  }

  <Fussnoten />

</>

export default Footer
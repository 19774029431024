import { z } from 'zod'
import { fromZodError } from 'zod-validation-error'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typeguard = <T>(schema: z.ZodSchema) => (value: unknown, log=true): value is T => {
  try {
    schema.parse(value)
    return true
  } catch (err) {
    if (!log) return false
    if (process.env.NODE_ENV !== 'development') return false

    const validationError = fromZodError(err as z.ZodError<unknown>)

    const path = validationError.stack?.match(/at ([\S+]+).*/gm)?.[3]
    console.warn(validationError.message, path)

    return false
  }
}

export default typeguard
/* React */
import { useEffect, useState } from 'react'

/* Redux */
import AppBarRedux from './AppBar.Redux'
import NavigationRedux from 'src/layout/Navigation/Navigation.Redux'

/* Next */
import Link from 'next/link'

/* Material */
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Typo from '@mui/material/Typography'
import Hidden from '@mui/material/Hidden'
import useStyles from './styles'

/* Material-Icons */
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import FavoriteIcon from '@mui/icons-material/Favorite'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

/* Components */
import Suche from './Suche'

const ToolbarContent: React.FC = () => {
  const classes = useStyles()

  const {
    toggleDrawer,
    showMobileSuche, toggleMobileSuche,
    warenkorbItems, wunschlisteItems,
    userToken,
  } = AppBarRedux()

  const { displayFilter } = NavigationRedux()

  if (showMobileSuche) return <Suche autoFocus fullWidth />

  return <>
    <IconButton
      edge='start'
      className={classes.menuButton}
      color='inherit'
      aria-label='menu'
      onClick={toggleDrawer}
      size='large'
    >
      <MenuIcon />
    </IconButton>

    <div className={classes.title}>
      <Link href={'/'} passHref legacyBehavior>
        <Typo component='a' variant='h6'>
          Home
        </Typo>
      </Link>
    </div>

    { displayFilter &&
      <Button 
        title="Filter anzeigen" className={classes.buttonFilter}
        color='inherit' variant='outlined' onClick={toggleDrawer}
      >
        Filter
      </Button>
    }

    <div className={classes.spacer}></div>

    <Hidden mdDown>
      <Suche />
    </Hidden>

    <Hidden mdUp>
      <Button 
        title="Im Shop suchen" color='inherit'
        onClick={toggleMobileSuche}
      >
        <SearchIcon />
      </Button>
    </Hidden>

    <Link href={'/kundenkonto'} passHref legacyBehavior>
      <IconButton 
        aria-label="Mein Kundenkonto" 
        color='inherit' size='large' 
        className={classes.buttonKonto}
      >
        <Badge 
          badgeContent={
            userToken 
              ? <CheckCircleIcon />
              : 0
          } 
          color='secondary'
        >
          <AccountCircleIcon />
        </Badge>
      </IconButton>
    </Link>

    <Link href={'/wunschliste'} passHref legacyBehavior>
      <IconButton 
        aria-label="Meine Wunschliste" 
        color='inherit' size='large' 
        className={classes.buttonFavs}
      >
        <Badge badgeContent={wunschlisteItems} color='secondary'>
          <FavoriteIcon />
        </Badge>
      </IconButton>
    </Link>

    <Link href={'/warenkorb'} passHref legacyBehavior>
      <IconButton 
        aria-label="Mein Warenkorb" 
        color='inherit' size='large'
      >
        <Badge badgeContent={warenkorbItems} color='secondary'>
          <LocalMallIcon />
        </Badge>
      </IconButton>
    </Link>
  </>
}

interface Props {
  headerHeight: number
}

const HeaderAppBar: React.FC<Props> = props => {
  const classes = useStyles()

  const [fixed, setFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => 
      setFixed(window.pageYOffset > props.headerHeight)

    window.addEventListener('scroll', handleScroll, { passive: true })
    
    return () => window.removeEventListener('scroll', handleScroll)

  }, [props.headerHeight])

  return <div className={classes.appBar}>

    {fixed && <Toolbar />}

    <AppBar 
      position={fixed ? 'fixed' : 'static'} 
      color='primary' enableColorOnDark
    >
      <Container maxWidth='lg'>
        <Toolbar>
          <ToolbarContent />
        </Toolbar>
      </Container>
    </AppBar>
    
  </div>
}

export default HeaderAppBar

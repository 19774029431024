import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import config from 'src/config'

import rootReducer from './reducer'

export let store: ReturnType<typeof configStore>

const { dev } = config

const configStore = () => configureStore({
  reducer: rootReducer,
  devTools: dev,
})

export const makeStore = () => {
  store = configureStore({
    reducer: rootReducer,
    devTools: dev,
  })
  return store
}

export const storeWrapper = createWrapper(makeStore, {
  debug: false,
})

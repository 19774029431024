/* Next */
import Link from 'next/link'
import Image from 'next-image-export-optimizer'

/* Material */
import Container from '@mui/material/Container'
import useStyles from './styles'

/* Images */
import Logo from '../logos/logo.svg'

/* Config */
import shopInfos from 'template/infos'

const Header: React.FC = () => {
  const classes = useStyles()
  
  return <div className={classes.headerWrapper}>
    <Container maxWidth='lg' className={classes.topContainer}>
      <Link href='/' legacyBehavior><a>
        <Image
          src={Logo}
          alt={shopInfos.name}
          width={186}
          height={70}
          unoptimized
        />
      </a></Link>
    </Container>
  </div>
}

export default Header

/* Next */
import Link from 'next/link'

/* Redux */
import DrawerRedux from './Drawer.Redux'

/* Material */
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import useStyles from './styles'

/* Material-Icons */
import FavoriteIcon from '@mui/icons-material/Favorite'
import HomeIcon from '@mui/icons-material/Home'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const NavigationPrimary: React.FC = () => {
  const classes = useStyles()
  const { toggle: toggleDrawer  } = DrawerRedux()

  const mobileNavMenueItems = [
    {
      name: 'menuHome',
      title: 'Home',
      icon: <HomeIcon />,
      href: '/',
    },
    {
      name: 'menuKonto',
      title: 'Kundenkonto',
      icon: <AccountCircleIcon />,
      href: '/kundenkonto',
    },
    {
      name: 'menuFavs',
      title: 'Wunschliste',
      icon: <FavoriteIcon />,
      href: '/wunschliste',
    },
  ] as const

  return <List className={classes.mainMenuList}>
    {mobileNavMenueItems.map(({ name, title, href, icon })=>
      <Link key={name} href={href} passHref legacyBehavior>
        <ListItem 
          component='li' button divider className={classes[name]}
          onClick={toggleDrawer}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      </Link>
    )}
  </List>
}

export default NavigationPrimary